export type ApiResponse<T = any> = {
  meta: {
    status: string;
    pagination?: {
      limit: number;
      offset: number;
      total_count: number;
    };
  };
  data: T;
};

export type ApiError = {
  statusCode: number;
  error?: string;
  message: string | string[];
  subject?: any;
  type?: string;
};

export type Jira = {
  jiraId: string;
  description: string;
};

export type IctoNumber = {
  ictoNumber: string;
};

export type Organisation = {
  orgId: string;
  name: string;
  billingTypes: BillingType[];
  minBillingItems: number;
  maxBillingItems: number;
};
export type User = {
  userId: number;
  email: string;
  gitlabId: number | null;
};

export type GroupMeta = {
  groupId: number;
  name: string;
  path: string;
  requester: User | null;
  admins: User[];
  billingItem?: string;
  createdAt: string;
  updatedAt: string;
  recertifiedAt: string;
  userCountInitial: number;
  userCountCurrent: number;
  orgId: string;
  organisation?: Organisation;
  jira?: Jira;
  approved: boolean;
  description?: string;
  recertifications?: Array<{ recertId: string }>;
};

export type Recertification = {
  recertId: string;
  expiresAt: string;
  createdAt: string;
  updatedAt: string;
  status: string;
  mailCount: number;
  gitlabGroup: GroupMeta;
  active: boolean;
};

export enum GitLabTokenScope {
  ReadAPI = 'ReadAPI', // all read scopes
  ReadWriteAPI = 'ReadWriteAPI', // read/write API access
  ReadAll = 'ReadAll', // read all resources
  ReadWriteAll = 'ReadWriteAll', // read/write all resources
}

export enum ResourceStatus {
  Requested = 'Requested',
  Approved = 'Approved',
  Rejected = 'Rejected',
  Enabled = 'Enabled',
  Disabled = 'Disabled',
}

export type Resource = {
  id: number;
  status: ResourceStatus;
  groupId: number;
  type: 'Generic' | 'ServiceAccount';
  requester?: User;
  requesterId: number;
  serviceAccount?: {
    username: string;
    hasGitLab: boolean;
    hasJFrog: boolean;
  };
  gitlabGroup: GroupMeta;
  generic?: {
    data: string;
  };
};

export type GitlabToken = {
  createdAt: string;
  expiresAt: string;
  description: string;
  gitlabId: number;
  id: number;
  requesterId: number;
  requester: {
    email: string;
  };
  revoked: boolean;
  serviceAccount?: {
    username?: string;
  };
  scope: GitLabTokenScope;
  token?: string;
};

export type BillingType = {
  billingTypeId: number;
  name: string;
  description: string;
  creatable: boolean;
  searchable: boolean;
  regex: string | null;
  priority: number;
};

export type BillingItem = {
  billingItemId?: string;
  value: string;
  billingType: BillingType;
};

export enum ErrorType {
  _UnknownError = '_UnknownError',
  _InvalidSession = '_InvalidSession',
  AuthProviderMissingEmail = 'AuthProviderMissingEmail',
  AuthTokenInvalid = 'AuthTokenInvalid',
  AuthTokenPayloadEmpty = 'AuthTokenPayloadEmpty',
  AuthTokenPayloadMissingEmail = 'AuthTokenPayloadMissingEmail',
  AuthTokenUnset = 'AuthTokenUnset',
  AuthUserDataUnset = 'AuthUserDataUnset',
  ForbiddenForceUpdate = 'ForbiddenForceUpdate',
  GitLabTokenNotFound = 'GitLabTokenNotFound',
  GitLabUserNotFound = 'GitLabUserNotFound',
  GroupAdminsUnset = 'GroupAdminsUnset',
  GroupAlreadyExists = 'GroupAlreadyExists',
  GroupBillingInformationUnset = 'GroupBillingInformationUnset',
  GroupNotFound = 'GroupNotFound',
  GroupOrganisationUnset = 'GroupOrganisationUnset',
  ICTONumberNotFound = 'ICTONumberNotFound',
  JiraProjectNotFound = 'JiraProjectNotFound',
  OrganisationNotFound = 'OrganisationNotFound',
  ResourceNotEnabled = 'ResourceNotEnabled',
  ResourceNotFound = 'ResourceNotFound',
  ServiceAccountAlreadyExists = 'ServiceAccountAlreadyExists',
  ServiceAccountGitLabUserExists = 'ServiceAccountGitLabUserExists',
  ServiceAccountGroupNotCertified = 'ServiceAccountGroupNotCertified',
  ServiceAccountInvalidUsernamePrefix = 'ServiceAccountInvalidUsernamePrefix',
  ServiceAccountNotCreatedOnGitLab = 'ServiceAccountNotCreatedOnGitLab',
  RecertificationAlreadyExists = 'RecertificationAlreadyExists',
  RecertificationNotFound = 'RecertificationNotFound',
}

export const UnknownError: ApiError = {
  statusCode: 0,
  message: 'Unknown error',
  type: ErrorType._UnknownError,
};

export const SessionError: ApiError = {
  statusCode: 0,
  message: 'Session error',
  type: ErrorType._InvalidSession,
};
