import { APIException } from '@/helpers/errors';
import { ApiError, UnknownError } from '@/types/commandBlock';
import { createStore } from 'zustand/vanilla';

export type AlertState = {
  success: string | null;
  error: string | ApiError | null;
};

export type AlertActions = {
  setSuccess: (success: string | null) => void;
  setError: (error: string | ApiError | null) => void;
  wrapPromise: <T = any>(promise: Promise<T>) => Promise<T | undefined>;
};

export type AlertStore = AlertState & AlertActions;

export const defaultInitialState: AlertState = {
  success: null,
  error: null,
};

export const createAlertStore = (
  initState: AlertState = defaultInitialState,
) => {
  return createStore<AlertStore>((set) => ({
    ...initState,
    setSuccess: (success) => set({ success }),
    setError: (error) => {
      if (!error || typeof error === 'string') {
        set({ error });
      } else if (error instanceof APIException) {
        set({ error: error.data });
      } else {
        console.error(error);
        set({ error: UnknownError });
      }
    },
    wrapPromise: async <T = any>(promise: Promise<T>) => {
      try {
        return await promise;
      } catch (error) {
        if (error instanceof APIException) {
          set({ error: error.data });
        } else {
          console.error(error);
          set({ error: UnknownError });
        }
        return undefined;
      }
    },
  }));
};
