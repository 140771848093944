'use client';

import { createAlertStore, type AlertStore } from '@/stores/alert-store';
import { createContext, useContext, useRef, type ReactNode } from 'react';
import { useStore } from 'zustand';

export type AlertStoreApi = ReturnType<typeof createAlertStore>;
export const AlertStoreContext = createContext<AlertStoreApi | undefined>(
  undefined,
);

export interface AlertStoreProviderProps {
  children: ReactNode;
}

export const AlertStoreProvider = ({ children }: AlertStoreProviderProps) => {
  const store = useRef<AlertStoreApi>();
  if (!store.current) {
    store.current = createAlertStore();
  }

  return (
    <AlertStoreContext.Provider value={store.current}>
      {children}
    </AlertStoreContext.Provider>
  );
};

export const useAlertStore = <T,>(selector: (store: AlertStore) => T) => {
  const store = useContext(AlertStoreContext);
  if (!store) {
    throw new Error('useAlertStore must be used within a AlertStoreProvider');
  }
  return useStore(store, selector);
};
