import '@/styles/globals.css';
import { SessionProvider } from 'next-auth/react';
import { appWithTranslation } from 'next-i18next';
import type { AppProps } from 'next/app';
import { useState } from 'react';
import { SWRConfig } from 'swr';

import RefreshTokenHandler from '@/components/refreshTokenHandler';
import { AlertStoreProvider } from '@/providers/alert-store-provider';
import { ThemeProvider } from 'next-themes';
import localFont from 'next/font/local';

const TeleNeo = localFont({
  src: [
    {
      path: '../../public/fonts/TeleNeoVariable.woff2',
    },
  ],
  variable: '--font-teleneo',
});

function App({ Component, pageProps }: AppProps) {
  const [interval, setInterval] = useState(0);
  return (
    <SessionProvider session={pageProps.session} refetchInterval={180}>
      <ThemeProvider enableSystem={false}>
        <SWRConfig value={{ provider: () => new Map() }}>
          <AlertStoreProvider>
            <div className={`${TeleNeo.variable} font-sans`}>
              <Component {...pageProps} />
            </div>
          </AlertStoreProvider>
        </SWRConfig>
      </ThemeProvider>
      <RefreshTokenHandler setInterval={setInterval} />
    </SessionProvider>
  );
}

export default appWithTranslation(App);
