import { useSession } from 'next-auth/react';
import { useEffect } from 'react';

const RefreshTokenHandler = ({ setInterval }: { setInterval: any }) => {
  const { data: session } = useSession();

  useEffect(() => {
    if (!!session) {
      const timeRemaining = Math.round(
        ((session as any).accessTokenExpires - 25 * 1000 - Date.now()) / 1000,
      );
      setInterval(timeRemaining > 0 ? timeRemaining : 0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [session]);

  return null;
};

export default RefreshTokenHandler;
